const TitleAndDescription = function(title = null, description = null) {
  if(title === null) {
    title = 'Agência Sonora - Incentivo, Promoção e Fidelidade';
  }

  if(description === null) {
    description = 'Os melhores cases de promoção, incentivo e relacionamento estão aqui no nosso site. Clique e confira.';
  }

  document.querySelector('meta[name="title"]').setAttribute('content', title);
  document.querySelector('meta[property="og:title"]').setAttribute('content', title);
  document.querySelector('meta[name="twitter:title"]').setAttribute('content', title);

  document.querySelector('meta[name="description"]').setAttribute('content', description);
  document.querySelector('meta[property="og:description"]').setAttribute('content', description);
  document.querySelector('meta[name="twitter:description"]').setAttribute('content', description);
}

export default TitleAndDescription;
