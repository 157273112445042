import React, { useEffect } from "react";
import { Fancybox } from '@fancyapps/ui';
import '@fancyapps/ui/dist/fancybox/fancybox.css';
import './BannerStyles.scss';
import {LinkClick} from "../../Resources/Utils/Gtm";
import {Link} from "react-router-dom";

const Banner = () => {

  useEffect(() => {
    document.addEventListener('scroll', function () {
      const scrollPosition = window.scrollY;
      const translateY = scrollPosition * 0.8;
      document.querySelector('.parallax-banner').style.transform = 'translate3d(0,' + translateY + 'px,0)';
    });
  });

  useEffect(() => {
    Fancybox.bind('[data-fancybox]');
  }, []);

  const handleClickVideo = () => {
    LinkClick('home', 'reel');
  };

  return (
    <div className="parallax" id="sonora">
      <div className="parallax-banner bg-black">
        <Link onClick={handleClickVideo} to="https://vimeo.com/427396952" data-fancybox></Link>
      </div>
    </div>
  );
};

export default Banner;
