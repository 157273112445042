import React from 'react';
import TemplateDefault from '../../Templates/TemplateDefault';
import Banner from '../../../../Layout/Banner';
import Cards from '../../../../Layout/Cards';
import Brands from '../../../../Layout/Brands';
import About from '../../../../Layout/About';
import Contact from '../../../../Layout/Contact';
import { WhatsAppFloating } from '../../../../Components/WhatsAppFloating';
import Init from "../../../Utils/Initializer";

const Home = () => {
  Init();
  return (
    <>
      <TemplateDefault>
        <WhatsAppFloating />
        <Banner />
        <Cards />
        <Brands />
        <About />
        <Contact />
      </TemplateDefault>
    </>
  );
};

export default Home;
