import { validateEmail } from './validateEmail';

export const checkIfNameHasSurname = ({
  watch,
  clearErrors,
  setError,
  fieldName,
}) => {
  const inputName = watch(fieldName);
  const hasFirstName = !!inputName?.split(' ')[0];
  const hasSurname = !!inputName?.split(' ')[1];

  if (hasFirstName && hasSurname === false) {
    setError(fieldName, {
      type: 'manual',
      message: 'Digite o nome completo.',
    });
  } else {
    clearErrors(fieldName);
  }
};

export const checkIfEmailIsValid = ({
  watch,
  clearErrors,
  setError,
  fieldName,
}) => {
  const inputEmail = watch(fieldName);
  const isValid = validateEmail(inputEmail);

  if (isValid || !inputEmail) {
    clearErrors(fieldName);
  } else {
    setError(fieldName, { type: 'manual', message: 'Insira um email válido.' });
  }
};

export const checkIfPhoneIsCompleted = ({
  watch,
  fieldName,
  setError,
  clearErrors,
}) => {
  const inputPhone = watch(fieldName);
  if (inputPhone.length > 1 && inputPhone.length < 15) {
    setError(fieldName, {
      type: 'manual',
      message: 'Número de telefone incompleto.',
    });
  } else {
    clearErrors(fieldName);
  }

  if (inputPhone?.length === 1) {
    clearErrors(fieldName);
  }
};
