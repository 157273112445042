import Client from './Client';
import { formatResponse } from './formatResponse';

export const GetCases = (limit, slug) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await Client.get(`/cases`, {
        params: { limit: limit, active: slug },
      });

      resolve(formatResponse(response));
    } catch (error) {
      resolve(formatResponse(error));
    }
  });
};
