import React from 'react';

import './AboutStyles.scss';

import lampada from '../../Resources/Media/Site/Pages/Home/About/lampada.webp';
import lampadaMob from '../../Resources/Media/Site/Pages/Home/About/lampada-mobile.webp';

const About = () => {
  return (
    <>
      <section className="bg-white about lg:pt-16 pt-2" id="sobre">
        <div className="container lg:mb-4 mb-6">
          <div className="brand-title lg:w-full w-[315px] mx-auto">
            <h2 className="text-orange tracking-[2px] font-bold text-[34px] lg:text-[44px] mb-0 pb-0">
              SOBRE
            </h2>
            <h3 className="font-normal text-purple text-[22px] lg:text-[26px] uppercase">
              Há mais de 10 anos, fazemos <br className="lg:hidden" />
              <span className="bg-orange text-white px-1 font-bold tracking-[1px]">
                promoções,
              </span>{' '}
              <br className="lg:block hidden" />
              campanhas de{' '}
              <span className="bg-orange text-white px-1 font-bold tracking-[1px]">
                incentivo e relacionamento
              </span>{' '}
              <br />
              virarem verdadeiros espetáculos.
            </h3>
          </div>
        </div>
        <div className="overflow-hidden">
          <div
            className="lg:grid justify-center items-center"
            style={{ gridTemplateColumns: '1.5fr 1.1fr' }}
          >
            <div>
              <picture>
                <source
                  media="(max-width: 767px)"
                  srcSet={lampadaMob}
                  alt="Lâmpada"
                ></source>
                <img src={lampada} alt="Lâmpada" className="w-full" />
              </picture>
            </div>
            <div className="container about-description">
              <hgroup className="lg:w-full w-[315px] mx-auto mt-4 lg:mt-0">
                <h2 className="font-medium lg:text-[46px] text-[36px] text-purple mb-3">
                  COMUNICAÇÃO
                </h2>
                <h2 className="font-medium lg:text-[46px] text-[36px] text-purple mb-3">
                  TECNOLOGIA
                </h2>
                <h2 className="font-medium lg:text-[46px] text-[36px] text-purple mb-3">
                  EXPERIÊNCIA
                </h2>
                <h2 className="font-medium lg:text-[46px] text-[36px] text-purple">
                  DADOS
                </h2>
              </hgroup>
              <div className="about-description lg:mt-[30px]">
                <p className="mb-5 mt-5 text-[#2D294B] leading-[23px]">
                  O nosso ritmo vem da{' '}
                  <span className="text-orange">conexão</span> entre a{' '}
                  <span className="text-orange">criatividade</span>
                  <br className="lg:hidden" /> e a{' '}
                  <span className="text-orange">tecnologia</span>;
                  <br className="hidden lg:block" /> da incessante busca pela
                  inovação
                  <br className="lg:hidden" /> e do espírito pioneiro; da
                  vontade <br className="hidden lg:block" />
                  de criar o novo <br className="lg:hidden" /> para oferecer o{' '}
                  <span className="text-orange">exclusivo</span>.
                </p>

                <p className="text-[#2D294B] leading-[23px]">
                  Dessa mistura de artes, tendências, negócios{' '}
                  <br className="lg:hidden" /> e a inquietude de estar
                  <br className="hidden lg:block" /> à frente, surge o{' '}
                  <span className="text-orange">beat</span>,
                  <br className="lg:hidden" /> que nos move e que agita
                  diariamente diferentes <br />
                  marcas, públicos e segmentos.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default About;
