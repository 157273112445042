import TemplateDefault from "../../../Templates/TemplateDefault";
import "./HavaianasStyles.scss";
import Cases from "../../../../../Components/Cases";
import { useEffect } from "react";
import AOS from "aos";
import { WhatsAppFloating } from "../../../../../Components/WhatsAppFloating";
import monitorOne from "../../../../Media/Site/Pages/Cases/Havaianas/monitor-1.webp";
import monitorOneMobile from "../../../../Media/Site/Pages/Cases/Havaianas/monitor-1-mobile.webp";
import monitorTwo from "../../../../Media/Site/Pages/Cases/Havaianas/monitor-2.webp";
import monitorTwoMobile from "../../../../Media/Site/Pages/Cases/Havaianas/monitor-2-mobile.webp";
import monitorTree from "../../../../Media/Site/Pages/Cases/Havaianas/monitor-3.webp";
import monitorTreeMobile from "../../../../Media/Site/Pages/Cases/Havaianas/monitor-3-mobile.webp";
import monitorFour from "../../../../Media/Site/Pages/Cases/Havaianas/monitor-4.webp";
import monitorFourMobile from "../../../../Media/Site/Pages/Cases/Havaianas/monitor-4-mobile.webp";
import Init from "../../../../Utils/Initializer";

const Havaianas = () => {
  Init();
  useEffect(() => {
    document.addEventListener("scroll", function () {
      const scrollPosition = window.scrollY;
      const translateY = scrollPosition * 0.8;
      document.getElementsByClassName("parallax-banner")[0].style.transform =
        "translate3d(0," + translateY + "px,0)";
    });
  });

  useEffect(() => {
    AOS.init({
      duration: 1000,
      once: true,
    });
  }, []);
  return (
    <TemplateDefault>
      <section className="havaianas">
        <div className="parallax">
          <div
            className="parallax-banner flex items-center justify-center"
            id="sonora"
          >
          </div>
        </div>
        <div className="havaianas-description relative pt-[19px] lg:pt-[59px] lg:pb-[85px] pb-8">
          <WhatsAppFloating />

          <p
            className="flex flex-col gap-[20px] pl-[61px] lg:leading-[45px]"
            data-aos="fade-right"
            data-aos-duration="1500"
          >
            O conforto da Havaianas e a diversão <br className="lg:hidden" />
            da Disney se uniram para um grande
            <br className="lg:hidden" /> espetáculo
            <br className="hidden lg:block" /> com a gente. Nessa{" "}
            <br className="lg:hidden" />
            promoção de co-branding, que <br className="lg:hidden" />
            aconteceu em meio à pandemia <br />
            do coronavírus, as 2 marcas queriam <br className="lg:hidden" />
            e conseguiram entrar na casa de seus <br className="lg:hidden" />
            consumidores, <br className="hidden lg:block" />
            para levar um pouco <br className="lg:hidden" />
            mais de bem-estar e alegria.
          </p>

          <div className="lg:pt-[40px]">
            <picture>
              <source
                media="(max-width: 767px)"
                srcSet={monitorOneMobile}
                alt="Monitor com case Havaianas"
              ></source>
              <img
                src={monitorOne}
                className="w-[345px] lg:w-[1280px] mx-auto lg:ml-[10rem] lg:mt-6 mt-12"
                alt="Monitor com case Havaianas"
              />
            </picture>
          </div>
          <p
            className="flex flex-col gap-[20px] pl-[61px] lg:mt-[-30px] lg:leading-[45px]"
            data-aos="fade-right"
            data-aos-duration="2000"
          >
            Para participar, era só comprar <br className="lg:hidden" />
            os produtos das linhas Disney, <br className="lg:hidden" />
            Marvel e Star Wars, cadastrar <br />
            a nota fiscal no site – criado <br className="lg:hidden" />
            na integra por nós -, girar a roleta <br className="lg:hidden" />
            e concorrer a 6 meses
            <br className="hidden lg:block" /> de acesso{" "}
            <br className="lg:hidden" />
            ao Disney +.
          </p>
        </div>
        <div className="relative bg-[#0050FF] pb-5 lg:pb-0">
          <div data-aos="fade-right">
            <picture className="relative left-[-75px] lg:left-[-220px] flex justify-start">
              <source
                media="(max-width: 767px)"
                srcSet={monitorTwoMobile}
              ></source>
              <img
                src={monitorTwo}
                className="lg:w-[1339px] w-[390px]"
                alt="Aventura na tela Havaianas no pé"
              />
            </picture>
          </div>
          <div data-aos="fade-left">
            <picture className="relative right-[-75px] lg:right-[-240px] flex justify-start lg:mt-[-50px]">
              <source 
              media="(max-width: 767px)" 
              srcSet={monitorTreeMobile}>
              </source>
              <img 
              src={monitorTree} 
              className="lg:w-[1339px] w-[390px]" 
              alt="Aventura na tela Havaianas no pé" />
            </picture>
          </div>
          <div data-aos="fade-right">
            <picture className="relative left-[-75px] lg:left-[-220px] flex justify-start lg:mt-[-50px] lg:mb-[-60px]">
              <source
                media="(max-width: 767px)"
                srcSet={monitorFourMobile}
              ></source>
              <img
                src={monitorFour}
                className="lg:w-[1339px] w-[390px]"
                alt="Aventura na tela Havaianas no pé"
              />
            </picture>
          </div>
        </div>

        <Cases />
      </section>
    </TemplateDefault>
  );
};

export default Havaianas;
