import React, { useEffect, useState } from 'react';

import '../../Layout/Cards/CardCasesStyles.scss';

import { GetCases } from '../../Backend/cases';
import { useLocation, useNavigate } from 'react-router-dom';

const Cases = () => {
  const [cases, setCases] = useState([]);
  const navigate = useNavigate();
  const location = useLocation();

  const loadCases = async (slug) => {
    const response = await GetCases(3, slug);
    setCases(response.data.cases);
  };

  useEffect(() => {
    let uri = location.pathname.replace('/', '');
    uri = uri.split("/")[1];
    loadCases(uri);
  }, []);

  return (
    <>
      <section className="cards cards--cases">
        <div className="container">
          <div className="cards-title">
            <h2 className="!text-white !text-[26px] lg:!text-[44px] !p-0 !ml-3 lg:!ml-0 !m-0">
              MAIS JOBS
            </h2>
          </div>
          <div className="cards-wrap lg:grid lg:grid-cols-3 gap-4 lg:mt-5 mt-4 mb-3">
          {cases?.map((card) => {
            const titleWithLineBreaks = card.title.replace("\\n", "<br>");

            return (
              <div className="cards-wrap-item" key={card.id}>
                <a
                  onClick={() => navigate(`/${card.slug}`)}
                  className="cursor-pointer flex gap-[10px] lg:flex-col"
                >
                  <figure>
                    <img src={card.image_url} alt="Imagem" />
                    <figcaption>
                      <div role="btn" style={{ whiteSpace: "pre-line" }}>
                        <h3
                          dangerouslySetInnerHTML={{
                            __html: titleWithLineBreaks,
                          }}
                        />
                        <p>{card.client_name}</p>
                      </div>
                      <span>+</span>
                    </figcaption>
                  </figure>
                </a>
              </div>
            );
          })}
          </div>
        </div>
      </section>
    </>
  );
};
export default Cases;
