/* eslint-disable react-hooks/exhaustive-deps */
import Button from '../../Components/Button';
import InputText from '../../Components/InputText';
import InputTextArea from '../../Components/InputTextArea';
import whatsapp from '../../Resources/Media/Site/Icons/whatsapp-purple.svg';
import email from '../../Resources/Media/Site/Icons/email-purple.svg';
import './ContactStyles.scss';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { schema } from './schema';
import { useContext, useRef, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { PostContactPage } from '../../Backend/Contact';
import {
  checkIfEmailIsValid,
  checkIfNameHasSurname,
  checkIfPhoneIsCompleted,
} from '../../Resources/Utils/formValidation';
import { LoadingContext } from '../../Shared/LoadingContext';
import {ButtonClick, LinkClick} from "../../Resources/Utils/Gtm";
import {Link} from "react-router-dom";

const Contact = () => {
  const [success, setSuccess] = useState(false);
  const [scrollToErrorUpdate, setScrollToErrorUpdate] = useState(0);
  const {
    control,
    handleSubmit,
    watch,
    setError,
    clearErrors,
    //setValue,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });

  const { setLoading } = useContext(LoadingContext);

  const recaptchaRef = useRef(null);

  const handleCustomSubmit = (data) => {
    recaptchaRef.current?.executeAsync().then((recaptchaToken) => {
      recaptchaRef.current.reset();
      onSubmit(data, recaptchaToken);
    });
  };

  const onSubmit = async (data, recaptchaToken) => {
    setLoading(true);
    ButtonClick('contato', 'envie');
    data['g-recaptcha-response'] = recaptchaToken;
    const response = await PostContactPage(data);

    if (response.success) {
      setLoading(false);
      setSuccess(true);
    } else {
      setLoading(false);
      for (const key in response.data) {
        setLoading(false);

        if (response.data.hasOwnProperty(key)) {
          const error = response.data[key][0];
          setError(key, { type: 'manual', message: error });
          setScrollToErrorUpdate(scrollToErrorUpdate + 1);
        }
      }
    }
  };

  return (
    <section
      className="contact flex items-center justify-center lg:mt-[142px] mt-[60px]"
      id="contato"
    >
      <div className="flex flex-col lg:gap-[20px] gap-[5px] lg:w-[720px] w-[315px]">
        <ReCAPTCHA
          ref={recaptchaRef}
          size="invisible"
          sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
          className="hidden"
        />
        {success && (
          <>
            <div className="flex flex-col justify-center lg:items-center items-start lg:mb-[14px] mb-[30px]">
              <h1 className="text-[#FC5F3E] lg:text-[44px] text-[34px] lg:pl-[10px]">
                MENSAGEM ENVIADA
              </h1>
              <p className="text-[#2D294B] font-light lg:text-[26px] text-[22px] leading-[40px]">
                <strong>Obrigado por entrar em contato.</strong>
                <br />
                Responderemos o mais breve possível.
                <br />
                <strong>Até logo.</strong>
              </p>
            </div>
          </>
        )}
        {!success && (
          <>
            <div className="lg:w-[720px] w-[315px] lg:mb-0 mb-[20px]">
              <h1 className="text-[34px] lg:text-[44px] !font-bold tracking-[2px]">
                CONTATO
              </h1>
              <h3 className="text-[22px] lg:text-[26px] mb-[20px]">
                FALE CONOSCO
              </h3>
              <div className="contact-inputs flex flex-col w-full gap-[20px]">
                <form onSubmit={handleSubmit(handleCustomSubmit)}>
                  <Controller
                    name="contact_name"
                    control={control}
                    render={({ field: { onChange, value } }) => {
                      return (
                        <InputText
                          value={value ? value : ''}
                          errors={errors.contact_name}
                          onChange={onChange}
                          label="Nome completo"
                          placeholder="Digite aqui seu nome completo"
                          dimensions="w-full lg:w-[720px]"
                          onBlur={() =>
                            checkIfNameHasSurname({
                              watch,
                              clearErrors,
                              setError,
                              fieldName: 'contact_name',
                            })
                          }
                        />
                      );
                    }}
                  />
                  <div className="flex flex-col lg:gap-[16px] lg:flex-row w-full contact-inputs-row">
                    <Controller
                      name="contact_email"
                      control={control}
                      render={({ field: { onChange, value } }) => {
                        return (
                          <InputText
                            value={value ? value : ''}
                            onChange={onChange}
                            label="E-mail"
                            placeholder="Digite aqui seu e-mail"
                            errors={errors.contact_email}
                            dimensions="w-full lg:w-[536px]"
                            onBlur={() =>
                              checkIfEmailIsValid({
                                watch,
                                clearErrors,
                                setError,
                                fieldName: 'contact_email',
                              })
                            }
                          />
                        );
                      }}
                    />

                    <Controller
                      name="contact_phone"
                      control={control}
                      render={({ field: { onChange, value } }) => {
                        return (
                          <InputText
                            value={value ? value : ''}
                            label="Telefone"
                            placeholder="Digite aqui seu telefone"
                            onChange={(e) => {
                              onChange(e.target.value);
                              clearErrors('contact_phone');
                            }}
                            dimensions="w-full lg:w-[168px]"
                            errors={errors.contact_phone}
                            mask="(99) 99999-9999"
                            inputMode="numeric"
                            onBlur={() => {
                              checkIfPhoneIsCompleted({
                                watch,
                                fieldName: 'contact_phone',
                                setError,
                                clearErrors,
                              });
                            }}
                          />
                        );
                      }}
                    />
                  </div>
                  <Controller
                    name="contact_message"
                    control={control}
                    render={({ field: { onChange, value } }) => {
                      return (
                        <InputTextArea
                          value={value ? value : ''}
                          label="Mensagem"
                          onChange={onChange}
                          labelStyles="w-full flex items-center"
                          errors={errors.contact_message}
                        />
                      );
                    }}
                  />
                  <div className="flex items-center justify-center relative h-[40px] mt-[15px]">
                    <Button
                      type="submit"
                      variant="contained w-full lg:w-[352px] hover:w-[384px] hover:h-[50px] absolute"
                      text="Envie"
                    />
                  </div>
                </form>
              </div>
            </div>
          </>
        )}
        <div className="flex gap-[15px] flex-col lg:items-end lg:mb-[47px] mb-[46px]">
          <div className="flex gap-[11px] pl-[20px] pr-[20px] lg:pr-[90px] lg:items-start">
            <img src={whatsapp} height="25" width="25" alt="Whatsapp" />
            <Link
              onClick={() => LinkClick('contato', 'whatsapp')}
              to="https://api.whatsapp.com/send?phone=5511976204242"
              target="_blank"
              rel="noreferrer"
              className="lg:text-[18px] text-[#2D294B] hover:text-[#fc5f3e] hover:cursor-pointer duration-[0.2s] !font-normal"
            >
              {' '}
              +55 11 97620-4242
            </Link>
          </div>
          <div className="flex gap-[11px] pl-[20px]">
            <img src={email} height="25" width="25" alt="E-mail" />
            <Link
              onClick={() => LinkClick('contato', 'email')}
              to="mailto:comercial@agenciasonora.com.br"
              target="_blank"
              rel="noreferrer"
              className="lg:text-[18px] text-[#2D294B] hover:text-[#fc5f3e] hover:cursor-pointer duration-[0.2s] !font-normal"
            >
              {' '}
              comercial@agenciasonora.com.br
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Contact;
