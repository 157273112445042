import React, { useEffect, useState } from 'react';

import './BrandsStyles.scss';

import { GetBrands } from '../../Backend/brands';
import { FaSpinner } from 'react-icons/fa';

const Brands = () => {
  const [brands, setBrands] = useState([]);
  const [loadingBrands, setLoadingBrands] = useState(true);
  const [imagesLoaded, setImagesLoaded] = useState(0);
  const [showImages, setShowImages] = useState(false);

  const getBrands = async () => {
    const response = await GetBrands();

    if (response.success) {
      setBrands(response.data);
    }

    setLoadingBrands(false);
  };

  const handleImageLoad = () => {
    setImagesLoaded((prev) => prev + 1);
  };
  useEffect(() => {
    getBrands();
  }, []);

  useEffect(() => {
    if (imagesLoaded === brands?.clients?.length) {
      setShowImages(true);
    }
  }, [brands?.clients?.length, imagesLoaded]);

  return (
    <section className="brands lg:pt-9 lg:mt-0 mt-[30px] mb-5" id="clientes">
      <div className="container">
        <div className="brand-title">
          <h2 className="text-orange font-bold text-[34px] lg:text-[44px] mb-0 pb-0">
            CLIENTES
          </h2>
          <h3 className="font-normal text-purple text-[22px] lg:text-[26px]">
            MARCAS QUE TOCAM O BEAT
          </h3>
        </div>
      </div>
      <div className="container p-0 mt-5">
        {loadingBrands ? (
          <div className="flex items-center justify-center bg-white">
            <FaSpinner className="animate-spin mr-2 h-[30px] w-[30px]" />
          </div>
        ) : (
          <div
            className={`${
              showImages ? 'opacity-100' : 'opacity-0'
            } brands-wrap grid grid-cols-3 gap-[10px] lg:gap-4 justify-center lg:grid-cols-6`}
          >
            {brands?.clients?.map((brandLogo, index) => (
              <div
                className="brands-wrap-item flex items-center justify-center w-[99px] h-[72px] lg:w-[168px] lg:h-[120px]"
                key={index}
              >
                <img
                  className="w-[72px] lg:w-[100px]"
                  src={brandLogo.url}
                  title={brandLogo.name}
                  alt={brandLogo.name}
                  onLoad={() => handleImageLoad()}
                />
              </div>
            ))}
          </div>
        )}
      </div>
    </section>
  );
};

export default Brands;
