import Client from './Client';
import { formatResponse } from './formatResponse';

export const GetBrands = (isFooter) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await Client.get(
        `/clients/${isFooter ? '?limit=3' : ''}`
      );
      resolve(formatResponse(response));
    } catch (error) {
      resolve(formatResponse(error));
    }
  });
};
