import React, { useState } from 'react';
import { useInView } from 'react-intersection-observer';

const AnimatedPercentageCounter = ({ value }) => {
    const [isVisible, setIsVisible] = useState(false);
    const [percentage, setPercentage] = useState(0);

    const [ref, inView] = useInView({
        triggerOnce: true,
    });

    if (inView && !isVisible) {
        setIsVisible(true);

        const interval = setInterval(() => {
            setPercentage((prevPercentage) => {
                const nextPercentage = prevPercentage + 1;
                if (nextPercentage >= value) {
                    clearInterval(interval);
                    return value;
                }
                return nextPercentage;
            });
        }, 40);
    }

    return (
        <>
            <div ref={ref}>
                {isVisible && <h1 className='text-[64px] text-white leading-none'>{percentage}<span className='text-[26px]'>%</span></h1>}
            </div>
        </>
    );
};

export default AnimatedPercentageCounter;

