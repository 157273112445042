/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import './FooterStyle.scss';
import Instagram from '../../Resources/Media/Site/Icons/instagram.svg';
import LinkedIn from '../../Resources/Media/Site/Icons/linkedin.svg';
import Sonora from '../../Resources/Media/Site/Icons/selo.svg';
import { LinkClick } from '../../Resources/Utils/Gtm';
import { Link } from 'react-router-dom';

const Footer = () => {
  const handleNavigate = (e) => {
    const target = e.target.innerText
      .toString()
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
      .toLowerCase()
      .trim()
      .replace(/\s+/g, '-')
      .replace(/[^\w-]+/g, '')
      .replace(/--+/g, '-');
    LinkClick('footer', target);
  };

  const handleNavigateSocial = (e) => {
    const target = e.target
      .getAttribute('alt')
      .toString()
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
      .toLowerCase()
      .trim()
      .replace(/\s+/g, '-')
      .replace(/[^\w-]+/g, '')
      .replace(/--+/g, '-');
    LinkClick('footer', target);
  };

  return (
    <footer className="footer bg-[#2D294B]">
      <div className="container p-0">
        <div className="footer-wrap">
          <nav className="footer-wrap-menu order-3 lg:order-1 lg:mt-0 mt-2">
            <ul className="lg:flex gap-8">
              <li>
                <Link
                  onClick={handleNavigate}
                  to="https://agenciasonora.com.br/politica-privacidade-agencia-sonora.pdf"
                  target="_blank"
                >
                  Política de privacidade
                </Link>
              </li>
              <li>
                <Link
                  onClick={handleNavigate}
                  to="https://wkf.ms/3Q1csP1"
                  target="_blank"
                >
                  Trabalhe conosco
                </Link>
              </li>
            </ul>
          </nav>
          <div className="order-1 lg:order-2">
            <picture>
              <img src={Sonora} alt="Sonora" width={64} />
            </picture>
          </div>
          <div className="footer-social order-2 lg:order-3 lg:mt-0 mt-[30px]">
            <ul className="flex gap-8">
              <li>
                <Link
                  onClick={handleNavigateSocial}
                  to="https://www.linkedin.com/company/9256407"
                  rel="noreferrer noopener"
                  target="_blank"
                >
                  <img src={LinkedIn} title="LinkedIn" alt="LinkedIn" />
                </Link>
              </li>
              <li>
                <Link
                  onClick={handleNavigateSocial}
                  to="https://www.instagram.com/agenciasonora/"
                  rel="noreferrer noopener"
                  target="_blank"
                >
                  <img src={Instagram} title="Instagram" alt="Instagram" />
                </Link>
              </li>
            </ul>
          </div>
        </div>
        <div className="flex justify-center">
          <p className="font-medium text-center whitespace-nowrap	text-[#8D79B4]">
            Powered by SONORA
            <br />
            ORB Marketing Ltda. - CNPJ 18.558.240/0001-71
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
