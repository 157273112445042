import * as Yup from 'yup';

export const schema = Yup.object({}).shape({
  contact_name: Yup.string().required('Esta informação é necessária.').test('VerificaSobrenome', 'Digite seu nome completo.', (value) => {
    const hasSobrenome = !!value.split(' ')[1];
    return hasSobrenome;
  }),
  contact_email: Yup.string()
    .email('Insira um e-mail válido.')
    .required('Esta informação é necessária.'),
  contact_phone: Yup.string().required('Esta informação é necessária.'),
  contact_message: Yup.string().required('Esta informação é necessária.'),
});
