import Client from './Client';
import { formatResponse } from './formatResponse';

export const PostContactPage = (payload) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await Client.post('/contact', { ...payload });
      resolve(formatResponse(response));
    } catch (error) {
      resolve(formatResponse(error));
    }
  });
};
