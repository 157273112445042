import WhatsappIcon from '../../Resources/Media/Site/Icons/whatsapp.svg';

export const WhatsAppFloating = () => {
  return (
    <a
      href="https://api.whatsapp.com/send?phone=5511976204242"
      target="_blank"
      rel="noreferrer"
    >
      <img
        src={WhatsappIcon}
        alt=""
        width={80}
        className="whatsapp-floating fixed right-[20px] bottom-[12px] !w-[15%] z-[9999] !max-w-[80px]"
      />
    </a>
  );
};
