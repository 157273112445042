const PageView = function() {
  window.dataLayer?.push({
    event: 'page_view',
    page: {
      location:
        document.location.origin +
        document.location.pathname +
        document.location.search,
      title: document.title,
    }
  });
}

export const ButtonClick = function(component, text) {
  window.dataLayer?.push({
    event: 'button_click',
    component: component,
    text: text
  });
}

export const LinkClick = function(component, text) {
  window.dataLayer?.push({
    event: 'link_click',
    component: component,
    text: text
  });
}

export default PageView;
