import NotFound from './Resources/Views/Site/NotFound';
import Home from './Resources/Views/Site/Home';
import Health from './Resources/Views/Core/Health';
import { Routes, Route } from 'react-router-dom';
import { useContext } from 'react';
import { LoadingContext } from './Shared/LoadingContext';
import LoadingIcons from 'react-loading-icons';
import Bridgestone from './Resources/Views/Site/Cases/Bridgestone';
import Neoquimica from './Resources/Views/Site/Cases/Neoquimica';
import Engov from './Resources/Views/Site/Cases/Engov';
import Havaianas from './Resources/Views/Site/Cases/Havaianas';
import Bv from './Resources/Views/Site/Cases/Bv';
import Honda from './Resources/Views/Site/Cases/Honda';
import PagueMenos from './Resources/Views/Site/Cases/PagueMenos';
import Hypera from './Resources/Views/Site/Cases/Hypera';
import Yamaha from './Resources/Views/Site/Cases/Yamaha';

function App() {
  const { loadingState } = useContext(LoadingContext);
  return (
    <>
      {loadingState && (
        <div className="loading">
          <div className="ico">
            <LoadingIcons.SpinningCircles />
          </div>
        </div>
      )}
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/sonora-health" element={<Health />} />
        <Route path="/bridgestone/melhores-bridgestone" element={<Bridgestone />} />
        <Route path="/havaianas/aventura-na-tela-havaianas-no-pe" element={<Havaianas />} />
        <Route path="*" element={<NotFound />} />
        <Route path="/neo-quimica/n-motivos-para-ganhar" element={<Neoquimica />} />
        <Route path="/hypera/bonde-da-curticao-engov-after" element={<Engov />} />
        <Route path="/banco-bv/promocao-ta-pago" element={<Bv />} />
        <Route path="/honda-servicos-financeiros/soumais-banco-honda" element={<Honda />} />
        <Route path="/pague-menos/promocao-40-anos" element={<PagueMenos />} />
        <Route path="/hypera/jornada-hypera" element={<Hypera />} />
        <Route path="/yamaha/me-leva" element={<Yamaha />} />
      </Routes>
    </>
  );
}

export default App;
