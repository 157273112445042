import React, { useEffect } from "react";
import TemplateDefault from "../../../Templates/TemplateDefault";
import AOS from "aos";
import "aos/dist/aos.css";
import "./HyperaStyles.scss";
import Cases from "../../../../../Components/Cases";
import { WhatsAppFloating } from "../../../../../Components/WhatsAppFloating";
import premios from "../../../../Media/Site/Pages/Cases/Hypera/premios.webp";
import videoHypera from "../../../../Media/Site/Pages/Cases/Hypera/hypera_jornada_videolancamento.mp4";
import Init from "../../../../Utils/Initializer";

const Hypera = () => {
  Init();
  useEffect(() => {
    AOS.init({
      duration: 1000,
      once: true,
    });
  }, []);

  useEffect(() => {
    document.addEventListener("scroll", function () {
      const scrollPosition = window.scrollY;
      const translateY = scrollPosition * 0.8;
      document.getElementsByClassName("parallax-banner")[0].style.transform =
        "translate3d(0," + translateY + "px,0)";
    });
  });

  return (
    <>
      <TemplateDefault>
        <article className="hypera">
          <WhatsAppFloating />

          <section className="hypera-parallax">
            <div
              className="hypera-parallax-banner parallax-banner flex items-center justify-center"
              id="sonora"
            >
            </div>
          </section>

          <section className="hypera-intro bg-gradient-to-t from-[#FF00FF] to-[#13006C]">
            <div className="pt-12 lg:pb-5 pb-12">
              <p
                className="text-white lg:text-[24px] text-[22px] lg:w-[804px] w-[294px] mx-auto lg:mb-10  mb-3 lg:mt-5 lg:leading-[45px]"
                data-aos="fade-right"
                data-aos-duration="1500"
              >
                Mais que uma campanha,
                <br className="lg:hidden" /> a Jornada Hypera é um clássico
                <br className="lg:hidden" /> programa de incentivo da empresa,{" "}
                <br className="lg:hidden" />
                com total engajamento de toda
                <br className="lg:hidden" /> cadeia de vendas interna.
                <br className="lg:hidden" /> Há 3 anos, somos os responsáveis{" "}
                <br className="lg:hidden" />
                por executar e aplicar melhorias contínuas no projeto, como
                conceitos temáticos e lúdicos anuais, desenvolvimento e execução
                <br className="lg:hidden" /> de réguas de comunicação,
                <br className="lg:hidden" /> elaboração de gamificações{" "}
                <br className="lg:hidden" />
                e aceleradores, diversidade
                <br className="lg:hidden" /> de formas de pontuação
                <br className="lg:hidden" /> e resgate de prêmios, entre
                <br className="lg:hidden" /> muitas outras inovações.
              </p>
            </div>
            <div className="hypera-intro-awards lg:pb-12 pb-[12rem] relative">
              <picture>
                <img
                  src={premios}
                  alt="Prêmios Jornada Hypera"
                  className="lg:block hidden lg:w-[1088px] w-[385px] mx-auto"
                />
              </picture>
            </div>
          </section>

          <section className="hypera-content lg:pt-[83px] pt-[51px]">
            <div className="hypera-content-engagement" data-aos="fade-right" data-aos-duration="1500">
              <p className="text-white lg:text-[24px] text-[22px] lg:w-[804px] w-[302px] mx-auto lg:mb-12 mb-4">
                E o melhor dessa jornada que trilhamos juntos da Hypera
                <br className="lg:hidden" /> são os resultados:
              </p>
              <p className="text-white lg:text-[24px] text-[22px] lg:w-[804px] w-[302px] mx-auto lg:mt-5 mt-[32px] lg:leading-[44px] leading-[32px]">
                <ul className="ml-3">
                  <li>
                    Média de engajamento e interação com comunicações de{" "}
                    <strong className="font-medium text-[#FC00FD]">97%</strong>;
                  </li>
                  <li>
                    <strong className="font-medium text-[#FC00FD]">80%</strong>{" "}
                    dos participantes resgatam prêmios mensalmente;
                  </li>
                  <li>
                    Períodos de atingimento
                    <br className="lg:hidden" /> de metas de vendas de até{" "}
                    <strong className="font-medium text-[#FC00FD]">70%</strong>;
                  </li>
                  <li>
                    <strong className="font-medium text-[#FC00FD]">85%</strong>{" "}
                    de envolvimento em ações especiais apresentadas no
                    <br className="lg:hidden" /> decorrer do ano.
                  </li>
                </ul>
              </p>
            </div>
            <div className="hypera-content-smarth lg:mt-[6rem] mt-[4rem] lg:pb-[768px] pb-[20rem]">
              <div className="mx-auto lg:w-[1366px] w-[412px]"></div>
            </div>
            <div className="hypera-content-video">
              <div data-aos="fade-right"></div>
              <div data-aos="fade-left">
                <video
                  loop
                  autoPlay
                  playsInline
                  muted
                >
                  <source src={videoHypera} type="video/mp4" />
                  Seu navegador não suporta o elemento de vídeo.
                </video>
              </div>
            </div>
          </section>

          <Cases />
        </article>
      </TemplateDefault>
    </>
  );
};
export default Hypera;
