import { useState } from 'react';

import './InputTextAreaStyles.scss';

const InputTextArea = (props) => {
  return (
    <>
      <div
        className={`${props.errors ? 'error' : ''} 
         inputs-container ${props.classInputContainer}`}
      >
        <label
          className={`${props.labelStyles} px-4 py-1 font-bold lg:text-center text-start tracking-[1px]`}
        >
          {props.label}
        </label>
        <div className={`${props.errors ? 'error' : ''}  `}>
          <textarea
            className="mt-[3px] lg:h-[175px] h-[233px]"
            {...props}
            placeholder={props.placeholder}
            rows={11}
          />
        </div>
        {props.errors && (
          <div className="errors-message">
            {/* <img src={RightCornerElement} alt="" /> */}
            <i className="error">{props.errors.message}</i>
          </div>
        )}
      </div>
    </>
  );
};

export default InputTextArea;
