import React, { useEffect, useState } from 'react';

import './CardCasesStyles.scss';

import { useNavigate } from 'react-router-dom';
import { GetCases } from '../../Backend/cases';

const Cards = () => {
  const [cases, setCases] = useState([]);
  const navigate = useNavigate();

  const loadCases = async () => {
    const response = await GetCases();
    setCases(response.data.cases);
  };

  useEffect(() => {
    loadCases();
  }, []);

  return (
    <section className="cards cards--cases" id="cases">
      <div className="container">
        <div className="cards-title">
          <hgroup className="lg:flex lg:justify-center lg:gap-[60px]">
            <h2>PROMOÇÃO</h2>
            <h2>INCENTIVO</h2>
            <h2>RELACIONAMENTO</h2>
          </hgroup>
        </div>
        <div className="cards-wrap lg:grid lg:grid-cols-3 gap-4">
          {cases?.map((card) => {
            const titleWithLineBreaks = card.title.replace('\\n', '<br>');

            return (
              <div className="cards-wrap-item" key={card.id}>
                <a
                  onClick={() => navigate(`/${card.slug}`)}
                  className="cursor-pointer flex gap-[10px] lg:flex-col"
                >
                  <figure>
                    <img src={card.image_url} alt="Imagem" />
                    <figcaption>
                      <div role="btn" style={{ whiteSpace: 'pre-line' }}>
                        <h3
                          dangerouslySetInnerHTML={{
                            __html: titleWithLineBreaks,
                          }}
                        />
                        <p>{card.client_name}</p>
                      </div>
                      <span>+</span>
                    </figcaption>
                  </figure>
                </a>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default Cards;
