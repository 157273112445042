import { useState } from 'react';
import InputMask from 'react-input-mask';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import './InputTextStyles.scss';
// import RightCornerElement from '../../Resources/Media/Icons/triangle.png';

const InputText = (props) => {
  const [hideInput, setHideInput] = useState('password');

  const handleChangeTypeInputPassword = () => {
    if (hideInput === 'text') {
      setHideInput('password');
    } else {
      setHideInput('text');
    }
  };

  return (
    <>
      <div
        className={`${props.errors ? 'error' : ''} 
         inputs-container ${props.classInputContainer}`}
      >
        <label className={`pl-4 font-bold ${props.labelStyles} tracking-[1px]`}>
          {props.label}
        </label>
        <div
          className={`${
            props.errors ? 'error' : ''
          } input-field rounded-xl md:h-10 xl:h-14 flex items-center justify-center mt-[3px] ${
            props.dimensions
          }`}
        >
          <InputMask
            {...props}
            placeholder={props.placeholder}
            type={props.inputPassword ? hideInput : 'text'}
            mask={props.mask}
            maskChar={null}
            maxLength={props.maxLength}
            className={props.disabled ? 'input-mask-disabled' : ''}
          />
          {!!props.inputPassword && (
            <div
              onClick={handleChangeTypeInputPassword}
              className="btn-eye-pass"
            >
              {hideInput === 'password' ? <FaEyeSlash /> : <FaEye />}
            </div>
          )}
        </div>
        {props.errors && (
          <div className="errors-message">
            {/* <img src={RightCornerElement} alt="" /> */}
            <i className="error">{props.errors.message}</i>
          </div>
        )}
      </div>
    </>
  );
};

export default InputText;
