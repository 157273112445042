/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useRef } from 'react';
import {Link, useLocation } from 'react-router-dom';
import Logo from '../../Resources/Media/Site/Header/logo.svg';
import LogoMenu from '../../Resources/Media/Site/Header/logo-menu.svg';
import MenuMobile from '../../Resources/Media/Site/Icons/menu.svg';
import CloseMenuMobile from '../../Resources/Media/Site/Icons/close.svg';

import './HeaderStyle.scss';

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);
  const menuRef = useRef(null);
  const touchStartX = useRef(null);
  const touchEndX = useRef(null);
  const location = useLocation();
  const { hash } = location;

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const handleTouchStart = (event) => {
    touchStartX.current = event.touches[0].clientX;
  };

  const handleTouchMove = (event) => {
    touchEndX.current = event.touches[0].clientX;
  };

  const handleTouchEnd = () => {
    if (touchStartX.current && touchEndX.current) {
      const touchDiff = touchEndX.current - touchStartX.current;
      if (touchDiff > 50) {
        setIsOpen(false);
      } else if (touchDiff < -50) {
        setIsOpen(true);
      }
    }
    touchStartX.current = null;
    touchEndX.current = null;
  };

  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  const scrollIntoHome = (elementId) => {
    const componentToScroll = document.getElementById(elementId);
    window.scrollBy({
      top: componentToScroll?.getBoundingClientRect().top - 100,
      behavior: 'smooth',
      scrollMode: 'always',
    });
  };

  useEffect(() => {
    if (isOpen) {
      document.body.classList.add('menu-open');
    } else {
      document.body.classList.remove('menu-open');
    }

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen]);

  useEffect(() => {
    const handleScroll = () => {
      if (hash === '#sonora') {
        scrollIntoHome('sonora');
      } else if (hash === '#cases') {
        scrollIntoHome('cases');
      } else if (hash === '#clientes') {
        scrollIntoHome('clientes');
      } else if (hash === '#sobre') {
        scrollIntoHome('sobre');
      } else if (hash === '#contato') {
        scrollIntoHome('contato');
      } else if (hash === '') {
        window.scrollTo({ top: 0, behavior: 'smooth' });
      }
    };

    const delay = 500;
    const timerId = setTimeout(() => {
      handleScroll();
    }, delay);

    return () => {
      clearTimeout(timerId);
    };
  }, [hash]);

  return (
    <header id="header" className="header">
      <div className="container">
        <div className="header-wrap">
          <div className="header-logo">
            <Link to="/#sonora">
              <img src={Logo} alt="Agência Sonora" />
            </Link>
          </div>

          <div
            className={`header-menu ${isOpen ? 'open' : ''}`}
            ref={menuRef}
            onTouchStart={handleTouchStart}
            onTouchMove={handleTouchMove}
            onTouchEnd={handleTouchEnd}
          >
            <div
              className={`header-menu-toggle ${isOpen ? 'open' : ''}`}
              onClick={toggleMenu}
            >
              <img
                src={isOpen ? CloseMenuMobile : MenuMobile}
                className="lg:hidden"
                alt=""
              />
            </div>

            <div
              className={`header-menu-items ${isOpen ? 'open' : ''}`}
              onClick={() => setIsOpen(false)}
            >
              <nav>
                <div className='lg:hidden'>
                  <picture>
                    <Link to="/#sonora">
                      <img
                        src={LogoMenu}
                        width={64}
                        className='mx-auto mb-[68px] mt-[50px]'
                        alt="Agência Sonora" />
                    </Link>
                  </picture>
                </div>
                <ul>
                  <li>
                    <Link
                      to="/#sonora"
                      onClick={toggleMenu}
                    >
                      SONORA
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/#cases"
                      onClick={toggleMenu}
                    >
                      CASES
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/#clientes"
                      onClick={toggleMenu}
                    >
                      CLIENTES
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/#sobre"
                      onClick={toggleMenu}
                    >
                      SOBRE
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/#contato"
                      onClick={toggleMenu}
                    >
                      CONTATO
                    </Link>
                  </li>
                  <li className='lg:hidden'>
                    <Link
                      to="https://wkf.ms/3Q1csP1"
                      target='_blank'
                      onClick={toggleMenu}
                    >
                      TRABALHE CONOSCO
                    </Link>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
